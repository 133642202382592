.past-projects {
    padding-bottom: 4vh;
    padding-top: 4vh;
    text-align: center;
    width: 100%;
  }
  
  .past-projects h2 {
    margin-bottom: 20px;
    font-size: 36px;
    color: #2c3e50;
    font-weight: bold;
  }
  
  .subheading {
    margin-bottom: 30px;
    font-size: 18px;
    color: #555;
    max-width: 800px;
    margin: 0 auto 30px;
  }
  
  .carousel {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    scroll-behavior: smooth;
    max-width: 1200px;
    margin: 0 auto;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
  
  .carousel-item {
    min-width: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    transition: transform 0.3s ease;
  }
  
  .project-logo {
    max-width: 280px;
    height: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    border-radius: 8px;
    padding: 10px;
    background-color: #fff;
  }
  
  .project-logo:hover {
    transform: scale(1.15);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  /* Media Queries for Responsiveness */
  
  /* For tablets and small laptops */
  @media (max-width: 1024px) {
    .carousel {
      max-width: 90%; /* Reduce width on smaller screens */
    }
  
    .carousel-item {
      min-width: 300px; /* Slightly smaller items */
    }
  
    .project-logo {
      max-width: 220px; /* Adjust logo size */
    }
  }
  
  /* For mobile devices */
  @media (max-width: 768px) {
    .past-projects h2 {
      font-size: 28px; /* Adjust heading size */
    }
  
    .subheading {
      font-size: 16px; /* Adjust subheading size */
      max-width: 90%; /* Reduce subheading width on mobile */
    }
  
    .carousel {
      max-width: 100%; /* Use full width on mobile */
    }
  
    .carousel-item {
      min-width: 240px; /* Smaller items for mobile */
      margin-right: 10px; /* Reduce spacing for better fit */
    }
  
    .project-logo {
      max-width: 180px; /* Smaller logo size for mobile */
    }
  }
  
  /* For very small devices (phones in portrait mode) */
  @media (max-width: 480px) {
    .past-projects h2 {
      font-size: 24px; /* Smaller heading size for tiny screens */
    }
  
    .subheading {
      font-size: 14px; /* Adjust subheading size */
    }
  
    .carousel-item {
      min-width: 200px; /* Even smaller items for small screens */
    }
  
    .project-logo {
      max-width: 150px; /* Further reduce logo size */
    }
  }