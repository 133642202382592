@media(max-width: 450px){
  #footer{
  flex-wrap:wrap;
}
.coppyright{
   width:100%!important;
}
.footerMenu{
  width:100%!important;
}
}

#footer{
  position: absolute;
  height: auto;
  background-color: rgb(26, 35, 63);
  width: 100%;
  display: flex;
  justify-content: space-around;
  color:white;
  flex-wrap:no-wrap;
}
.coppyright{
display:flex;
width:50%;
flex-direction: row;
justify-content: center;
}
.footerMenu {
  display:flex;
  width:50%;
  flex-direction: row;
  justify-content: center;
  //quickfix for smaller screen footer
  min-width: 140px;
}
.footerMenu>ul{
  list-style-type: none;
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-around;
  padding:0;
  font-size: 10px;
}
.footerMenu ul>li{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  width: 30%;
}
.leftRightC{
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-around;
  padding: 0;
  width: 45%;
  font-size: 10px;
}
.leftRightC>a{
color:white;
}