#partners{
  width:80%;
  margin-left:auto;
  margin-right:auto;
  height:auto;
  display:flex;
  flex-wrap: wrap;

}
#partnersTitle{
  width:100%;
  margin-left:auto;
  margin-right:auto;
  padding-bottom:10%;
  padding-top:15%;
}
#partnersLogos{
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  padding-bottom:15%;

}
#partnerLogo{
  width:22%;
  justify-content: space-between;
  flex-wrap: nowrap;
  background:blue;
}