@media(max-width:440px){
#testemonialss{
  max-height:initial!important;
}
.innerSlide{
  min-width: 100%!important;
}
.slide{
  min-width:100%!important;
}
#innerSliderText{
  width:100%;
  margin-top:1.33rem;
}
.slider{
  width:100%!important;
}

}
#testemonialss{
  display:flex;
  flex-direction:column;
  background-image: url("bg.png");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-width:100%;
  min-height:100vh;
  height: auto;
  /*max-height: 100vh;*/
  justify-content: center;
  flex-wrap: wrap;
}
#innerTestimonials{
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-top: 10%;
  padding-bottom: 15%;
}
/* sekcija customers*/

#testimonialsHeader{
  height: auto;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10%;
  color: white;
}
.slider-wrapper {
  display: flex;
  width: 100%;
  height: auto;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

}
.slide{
  padding-left: 5%;
  padding-right: 5%;
  min-width: 33%;
  display: flex;
  height: auto;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.slider{
  position: relative;
  width: 90%;
  margin: 0 auto;
  height: 60vh;
  overflow: hidden;
  white-space: nowrap;
  align-content: center;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}
.innerSlide {
  min-width: 24%;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  background: white;
  border-radius: 15px;
  white-space: normal;
  padding: 8% 4% 8% 4%;
  justify-content: space-evenly;

}
.client{
  height: auto;
  width: 100%;
  margin-top: 1.33rem;
  font-weight: bold;
}

#cntrols{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-evenly;
  width: 20%;
  margin-left: auto;
  margin-bottom: auto;
  margin-right: auto;
  padding-bottom:15%;
}

/*controll stilles*/
 .arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  color:white;
  cursor: pointer;
} 

.nextArrow {
  bottom: 5%;
  right: 25px;
  z-index: 999;
}

.backArrow {
  bottom: 5%;
  left: 25px;
  z-index: 999;
}

.fa-arrow-right:before, .fa-arrow-left:before {
  color: #222
}