@media(max-width:520px){
  #reasonsContent{
    flex-wrap:wrap!important;
  }
  #reasonsImg{
    width: 100%!important;
    max-width: 100%!important;
    padding-bottom: 15%!important;
    padding-top: 15%!important;
  }
  #reasonsText{
    width: 100%!important;
    max-width: 100%!important;
    text-align:unset!important;
  }
  #reasonsTitle{
  padding-bottom:0!important;
  }
  #WhatWDContainer{
    flex-wrap:wrap!important;
  }
  .WhatWDInnerContainer{
    max-width: 100%!important;
  }
  .WhatWDNode{
    flex-direction: column!important;
    padding-top:10%!important;
  }
  .WhatWDInnerNode h2{
    text-align: center!important;
  }
  .WhatWDText{
    text-align: center!important;
  }
  .WhatWDInnerNode{
    padding-top:10%;
  }
}



#servicess{
  height:auto;
  min-height: 100vh;
  width:100%;
  background: white;
}
#interestedServices{
  width:100%;
  height:auto;
  max-height:40vh;
  background: #1a233f
}
#interestedButtonServices>button{
cursor:pointer;
}

#getInTouch{
  border-radius: 15px;
    line-height: 1.7;
    background:rgb(57 183 187);
    color:#1a233f;
    padding: 1% 6% 1% 6%;
    margin-left: 2%;
    margin-top: 3%;
    border-color: transparent;
    cursor: pointer;
}
#interestedTitleServices{
  max-width: 80%;
  margin: 0% auto 0% auto;
  padding: 7% 0 7% 0;
}
#interestedTitleServices h2{
 margin:0;
 color: white;
}

#reasons {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-left:auto;
  margin-right:auto;
  height: auto;
  padding-bottom: 15%;
  padding-top: 7.5%;
}

#reasonsContent {
  display: flex;
  min-width: 100%;
  height: auto;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;
}

#reasonsImg {
  display: flex;
  min-width: 40%;
  max-width: 50%;
  margin-left:auto;
}
#reasonsText {
  min-width: 40%;
  max-width: 50%;
  margin-right:auto;
  text-align: left;
}
#reasonsTitle {
  max-width: 252px;
  margin: 0 auto;
  height: auto;
  padding-bottom:7.5%;
}
#reasonsTitle h2{
  margin:0;
  color:#1a233f;
}
/*DANI */

.whatWD{
  width:80%;
   height:auto;
  margin:7.5% auto 7.5% auto;
}

#innerWhatWD{
  display:flex;
  width: 100%;
  height:auto;
  justify-content: space-evenly;
  flex-wrap: nowrap;
  flex-direction: column;
}
#WhatWDTitle h2
{ 
  width:auto;
  text-align: center;
  margin-top:10%;
  margin:0;
  height:auto;
  color:#1a233f;
}
#WhatWDTitle{
  padding-bottom: 0%;
  padding-top: 7.5%;
}

#WhatWDContainer {
  display: flex;
  width:100%;
  height: auto;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-evenly;
}

.WhatWDInnerContainer {
  display: flex;
  height: auto;
  width: auto;
  max-width: 45%;
  flex-wrap: nowrap;
  flex-direction: column;
}
.WhatWDNode{
  display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    padding-top:20%;
}
.WhatWDInnerNode{

}

.WhatWDInnerNode h2{
  margin:0;
  color: #1a233f;
  font-size: 1rem;
  text-align: left;
}
.WhatWDIcons{
  padding-left: 5%;
  padding-right: 5%;
  height: auto;
}
.WhatWDText{
text-align: left;
font-size: 0.8rem;
}
