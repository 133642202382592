// Scaffolding
*, *:before, *:after { box-sizing: border-box; }
body { background-color: #f5f5f5; color: #333; font-size: 14px; font-family: Verdana, Arial, sans-serif; line-height: 20px; }
a { text-decoration: none; transition: all 0.3s linear 0s; }

.area {
  display: flex; flex-flow: row wrap; align-items: stretch; margin-left: auto; margin-right: auto;
  @media (min-width: 768px) { width: 750px; }
  @media (min-width: 992px) { width: 970px; }
  @media (min-width: 1200px) { width: 100%; }
}

// Navigation component
// ----------

// Variables
$navbar-height:              64px;
$navbar-background:          #fff;
$navbar-border:              #ddd;

$navbar-collapse-breakpoint: 768px;

$navbar-item-font-size:      14px;
$navbar-item-border-width:   4px;
$navbar-item-color:          #555;
$navbar-item-active-color:   #333;
$navbar-item-border:         transparent;
$navbar-item-active-border:  #673ab7;

// Component skeleton
.navbar-component {
  background-color: $navbar-background;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.16), 0 2px 10px rgba(0, 0, 0, 0.12);
  position: fixed;
    top: 0;
    width: 100%;
  & {> .navbar {
    justify-content: space-between;
  }}
  z-index: 10;
}

// Component
.navbar {
  // Brand
  & {> .brand {
    display: flex;
    flex-wrap: nowrap;
    font-size: 16px;
    color: #777;
    margin: round(($navbar-height - 20) / 2);
    margin-left: 5vw;
  }}
  
  // Toggle button
  & {> .toggle {
    border: 0;
    background-color: transparent;
    outline: none;
    margin-left:auto;
    border: 0;
    display: inline-block;
    background-color: transparent;
    background-image: none;
    vertical-align: middle;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    touch-action: manipulation;
    user-select: none;
    padding: round(($navbar-height - 20) / 2);
    
     @media (min-width: $navbar-collapse-breakpoint) {
       display: none;
     }
  }}
  
  & {> .toggle > .icon {
    position: relative;
    margin-top: 8px;
    margin-bottom: 8px;
    
    &,
    &:before,
    &:after {
      display: block;
      width: 24px;
      height: 3px;
      transition: background-color 0.3s linear, transform 0.3s linear;
      background-color: #555555;
    }
    
    &:before, &:after { position: absolute; content: ""; }
    &:before { top: -8px; }
    &:after { top: 8px; }
  }}
  
  & { .toggle.-active > .icon {
    background-color: transparent;
    
    &:before { transform: translateY(8px) rotate(45deg); }
    &:after { transform: translateY(-8px) rotate(-45deg); }
  }}
  
  // List of items
  & {> .list {
    display: none;
    flex-flow: row nowrap;
    align-items: center;
    white-space: nowrap;
    
    @media (min-width: $navbar-collapse-breakpoint) {
      display: flex;
    }
    
    @media (max-width: $navbar-collapse-breakpoint) {
      position: fixed;
      top: $navbar-height;
      left: 0;
      width: 100%;
      overflow-y: hidden;
      overflow-x: auto;
      border-top: 1px solid $navbar-border;
      background-color: $navbar-background;
    }
    
    &.-on {
      display: flex;
    }
  }}
  
  & {> .list > .item {
    display: block;
    flex-shrink: 0;
    height: $navbar-height;
    line-height: $navbar-height;
    padding-left: round(($navbar-height - 20) / 2);
    padding-right: round(($navbar-height - 20) / 2);
    text-transform: uppercase;
    color: $navbar-item-color;
    font-size: $navbar-item-font-size;
  }}
    
  & {> .list > .item.-link {
    line-height: $navbar-height + $navbar-item-border-width;
    color: $navbar-item-color;
    border-bottom: $navbar-item-border-width solid $navbar-item-border;
    
    &.-active,
    &:hover,
    &:focus {
      color: $navbar-item-active-color;
      border-bottom-color: $navbar-item-active-border;
    }
  }
}}

.headerNumber{
  background-color: lightcoral;
  text-align: center;
  padding: 10px;
  justify-content: space-around;
  flex-wrap: nowrap;
  border-radius: 25px 25px 0px 25px;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 2%;
  box-sizing: border-box;
  line-height: 15px;
  text-transform: lowercase;
}
#headerLogo{ 
margin:0;
height:25px;
}
#headerText{
  font-size: 22px;
    line-height: 25px;
    margin-top: 0;
    margin-bottom: 0;
    margin-left:8px;
}
#headerText>h2{
  font-size:22px;
  margin:0;
  color: #1a233f;
}
#headerLogoImg{

}
@media(max-width: 340px){
  .navbar > .toggle{
    margin-right: auto;
    flex-wrap: wrap;
    margin-left: auto;
    padding-top: 0;
    padding-bottom: 15px;
    padding-left:30%;
    padding-right:30%;
  }
  .navbar > .brand{
    margin-left: auto;
    margin-right: auto;
  }
}
@media(max-width:250px){
  .navbar > .brand{
   flex-wrap:wrap;
  }
  #headerText{
    margin: auto;
    }
  #headerLogo{
    margin:auto;
  }
}
@media(max-width:190px){
  #headerText > h2{
  font-size: 18px;
}
#headerText{
  display:none;
}

}