@media(max-width:440px){
#SocialForm{
  flex-wrap:wrap!important;
}
#form{
  flex-wrap:wrap!important;
}
#input-form{
  max-width: 100vw!important;
  width:100vw!important;
}
.map{
max-width: 100vw!important;
width:100vw!important;
}
#SocialForm{
  margin-top:15%!important;
}
.SocialFormContent{
  justify-content: initial!important;
}
}
@media(max-width:600px){
  .map{
    height: 40vh!important;
  }
  }
.styleInput:after{
  content:" *";
  color: red;
}

#form{
  min-height:100vh;
  display: flex;
  align-items: flex-end;
  background:white;
  flex-wrap: wrap;
}
#input-form{
  margin-left: auto;
  max-width: 50%;
  width: auto;
  z-index: 5;
  align-self: center;
}
#SocialForm{
  display:flex;
  max-width:100%;
  width:auto;
  z-index: 5;
  align-self: center;
  /*change on media query*/
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top:20%;
}
.SocialFormContent{
  display:flex;
  width:auto;
  z-index: 5;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  min-width: 30%;
}
.SocialFormText h3, .SocialFormText p{
position:relative;
font-size: 0.5em;
padding:0;
margin:0;
width:auto;
text-align: left;
line-height: 1.6;
}
.SocialFormIcon{
  color:rgb(57 183 187);
  padding-right: 7%;
}
.stylish input, textarea{
  outline: 0;
  border-width: 0 0 1px;
  border-color: black;
  line-height: 2;
  margin-bottom: 10vh;
  background: transparent;
  width:100%;
}
#input-form button{
  border-radius: 20px;
  border-style: solid;
  padding: 9px;
  color: white;
  display: flex;
  cursor: pointer;
  background: rgb(57 183 187);
  justify-self: flex-start;
}
.map{
  position: relative;
  z-index: 3;
  width: 50%;
  height: 100vh;
  filter: grayscale(100%);
  right:0;
}
.mp:after {
  width:30%;
  content:'';
  display:block;
  position:absolute;
  top:0;
  left:0;
  background: linear-gradient(to right, rgba(255,255,255,1.0) 0%,rgba(255,255,255,0.1) 94%);
  height:100%;
}

#input-form h1{
  text-align: left;
}
#interested{
  width:100%;
  height:auto;
  max-height:50vh;
  background:rgb(57 183 187);
}
#interestedInput>input{
  border-radius: 15px;
  border: 1px;
  border-style: solid;
  line-height: 1.7;
  border-color: white;
  padding: 1%;
  margin-left: 2%;
  margin-top: 3%;
  background: rgb(57 183 187);
  color: white;
}
#interestedInput>button{
  border-radius: 15px;
    line-height: 1.7;
    background: #1a233f;
    color: white;
    padding: 1% 6% 1% 6%;
    margin-left: 2%;
    margin-top: 3%;
    border-color: transparent;
}
#interestedTitle{
  max-width: 80%;
  margin:7% auto 7% auto;
}
#interestedTitle>form{
  display: flex;
  justify-content: space-evenly;
}
.inquiryFormHeader{
  width: 100%;
  padding-bottom: 3.5%;
  padding-top: 3.5%;
  font-size: 1.1rem;
  line-height: initial;
}
.inquiryContactInnerHeader{
  text-align: left;
  padding-bottom: 15%;
}
.inquiryContactInnerHeader h3{
  margin-bottom: 0;
  margin-top:0;
}