@media(max-width:500px){
  #partnersLogos{
    flex-wrap:wrap!important;
  }
  .partnerLogo1,.partnerLogo2,.partnerLogo3{
   width:100%!important;
  }
}

@media(max-width: 450px){
  #aboutInner{
    flex-wrap: wrap!important;
    padding-top: 10%;
    flex-direction: column-reverse;
  }
  #aboutCards{
    flex-wrap:wrap!important;
    width:100%!important;
  }
  #aboutText{
    width: 100%!important;
    text-align: center!important;
    padding-bottom: 15%!important;
  }
  #innerAboutText{
    margin-left:auto!important;
    margin-right:auto!important;
  }
  .rightCards, .leftCards{
    width:100%!important;
  }
  #aboutCardsL, #aboutCardsR{
    margin-top:0!important;
    margin-bottom:0!important;
  }

}
#livingOnTheEdge{
font-weight:initial;

}

#aboutt{
  height:auto;
  min-height: 100vh;
  width:100%;
  background: white;
}
#aboutInner{
  display:flex;
  height:auto;
  max-width: 80%;
  flex-wrap:nowrap;
  justify-content: center;
  margin-left:auto;
  margin-right:auto;
  padding-bottom:15%;
}
#aboutCards{
  display: flex;
  flex-wrap: nowrap;
  width: 55%;
}
#aboutCardsL{
  width:100%;
  height:auto;
  display:flex;
  justify-content: space-around;
  flex-direction: column;
  margin-bottom: 30%;
}
#aboutCardsR{
  width:100%;
  height:auto;
  display:flex;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 30%;
}
#aboutText{
width:45%;
text-align: left;
height:auto;
display:flex;
align-self: center;
}
.leftCards{
  background: white;
  color:black;
  width: 95%;
  height: auto;
  border-radius: 15px;
  margin-bottom: 5%;
  box-shadow: 5px 5px 10px 0px #888888;
}
.rightCards{
  background: white;
  color:black;
  width: 95%;
  height: auto;
  border-radius: 15px;
  margin-bottom: 5%;
  box-shadow: 5px 5px 10px 0px #888888;
}
.leftCards h2, .rightCards h2{
  color: black;
  font-size: 1rem;
  margin: 0;  
}
.rightCards:hover, .leftCards:hover{
  background:#1a233f;
  color:white;
  cursor:pointer;

}
.leftCards:hover > h2, .rightCards:hover > h2
{ color:white; }

#innerAboutText{
  width: 60%;
  margin-left: 15%;
}
.partnerLogo1{
  width:33%;
  height:auto;
}
.partnerLogo2{
  width:33%;
  height:auto;
}
.partnerLogo3{
  width:33%;
  height:auto;
}
#partnersTitle>h2{
  color: #1a233f;
}
.mediumTitle {
  padding-bottom: 10px;
  padding-top: 10px;
}
#innerAboutText h2{
  color:black;
}

#Aboutdetails{
   color: #1a233f;
   cursor: pointer;
}
