@media(max-width:400px){
.App > #homee{
min-height:35vh!important;
}
.app > #heroText{
height:50%!important;
}
}
@media(max-width: 700px){
#homee{
min-height:40vh!important;
}
#heroText{
height:50%!important;
}
}
@media(min-width:420px){
#centeredInnerHeroText h2{
font-size:2.5em!important;
}
}
#homee{
height:auto;
max-height: 100vh;
min-height: 70vh;
width:100%;
margin-top:69px;
}
h2 {
  color: white;
}
#heroPhoto{ 
  z-index:2;
  position: absolute;;
  justify-content:center;
  height:100%;
  width:100%;
  background-size:contain;
  background-repeat: no-repeat;  
}
#heroText{
  position: relative;
  z-index: 3;
  justify-content: left;
  height: 100%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  color:white;
}
#innerHeroText{
  width: 50%;
  max-height: 80%;
  min-height: 30%;
  height: 35%;
  align-items: center;
  display: Flex;
  color: white;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: auto;
  margin-top: 20%;
}
#centeredInnerHeroText{
  text-align:left;
}
#centeredInnerHeroText h2{
  text-align:left;
}
.hButton{
  margin-left:0!important;
  margin-top: 0.83rem!important;
}
