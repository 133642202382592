    .AboutUs {
        text-align: center;
        overflow:hidden;
        font-family: 'Montserrat', sans-serif;
      }
    .AboutUs h2{
        color:#1a233f;
        margin: 0;
        font-size: 1.2rem;
    }
    #AboutUsText{
        height: auto;
        overflow:hidden;
        min-height: 100vh;
        width: 80%;
        margin-top: 69px!important;
        margin:7.5% auto 7.5% auto; 
    }
    #AboutUsTextt{
      font-size: 0.8rem;
      text-align: left;
      margin-top: 5.5%;
    }
    #AboutUsImage{
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      height: 80vh;
      margin-top: 5.5%;
    }
    #inquiryBolder{
      font-weight: 900;
    }

    #AboutUsTextTitle{
        padding-bottom: 0%;
        padding-top: 5.5%;
    }
      .App-header {
        background-color: #282c34;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white;
      }
      
      .App-link {
        color: #61dafb;
      }
      #InquiryTextTitle{
        color: #1a233f;
        margin: 0;
        font-size: 2.5em !important;
        padding-top: 7.5%;
        line-height: initial;
      }
      .inquiryTextTitleInner{
        max-width: 60%;
        color: white !important;
        text-align: left;
        margin-left: 5% !important;
        margin-top: 5% !important;
        font-size: 1.1rem;
      }

      .Inquiry {
        text-align: center;
        overflow:hidden;
        font-family: 'Montserrat', sans-serif;
      }
    .Inquiry h2{
        color:#1a233f;
        margin: 0;

    }
    #InquiryText{
        height: auto;
        overflow:hidden;
        min-height: 100vh;
        width: 80%;
        margin-top: 69px!important;
        margin:7.5% auto 7.5% auto; 
        margin-bottom: 6%;
    }
    #InquiryTextt{
      font-size: 0.8rem;
      text-align: left;
      margin-top: 42%;
      margin-bottom: 5.5%;
    }
    #InquiryTextt>h1{
      text-align: center;
      line-height: 3rem;
    }
    #InquiryImage{
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      height: 715px;
      margin-top: 1.5%;
    }
    #InquiryTitle{
        padding-bottom: 0%;
        padding-top: 5.5%;
    }
    #innerInquiryTextContainer{
      min-height: 60vh;
      background-image: url(/static/media/hero.c7dfac46.png);
      background-size: contain;
      background-repeat: no-repeat;
    }
    .reportImgOuterContainer{
      display: flex;
    position: relative;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    }
    .reportImgContainer>img{
      display: block;
      max-width: 100%;
    }
    @media(max-width:400px){
      .App > #homee{
      min-height:35vh!important;
      }
      .app > #heroText{
      height:50%!important;
      }
      .reportImgContainer{
          margin-bottom: 8%;
      }
      .reportImgContainer>img{
        max-width: 100%!important;
      }
      }
      @media(max-width: 700px){
        #innerInquiryTextContainer{
          min-height: 22vh!important;
        }
      #InquiryTextTitle{
        font-size: 1.4em !important;
      }
      }
      @media(min-width:420px){
      #centeredInnerHeroText h2{
      font-size:2.5em!important;
      }
    }
      @media(min-width:1000px){
        .inquiryTextTitleInner{
          max-width: 60%;
          color: white !important;
          text-align: left;
          margin-left: 5% !important;
          margin-top: 5% !important;
          font-size: 3.4rem;
        }
        .InquiryImageSlo{
          background-image: url(./bannerSlo.png)!important;
          background-color: white;
          background-size: contain;
          background-color: 'white';
          background-size: 'contain';
        }
        .InquiryImageEn{
          background-image: url(./bannerEng.png)!important;
          background-color: white;
          background-size: contain;
          background-color: 'white';
          background-size: 'contain';
        }
      }
      @media(max-width:1000px){
        .InquiryImageSlo{
          background-image: url(./bannerMobileSlo.png)!important;
          background-color: white;
          background-size: contain;
          background-color: 'white';
          background-size: 'contain';
        }
        .InquiryImageEn{
          background-image: url(./bannerMobileEng.png)!important;
          background-color: white;
          background-size: contain;
          background-color: 'white';
          background-size: 'contain';
        }
      }